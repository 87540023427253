import React, {
	Fragment,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { flushSync } from "react-dom";
import {
	approveAppointment,
	deleteAppointment,
	fetchAppointments,
} from "../../../api/appointment.api";
import { TokenContext } from "../AdminLayout";
import * as S from "./AdminAppointments.styles";
import PriceInfo from "../../../data/services.json";
import dayjs from "dayjs";
import {
	formatFromTo,
	getWeekDayName,
	timeslotsToCalendarTimeslots,
} from "../../../util/date";
import Button from "../../Button/Button";
import Calendar from "../../Calendar/Calendar";
import {
	FreeTimeslots,
	Timeslot,
} from "../AdminAvailability/AdminAvailability";
import { getFreeTimeslotsAfter } from "../../../api/timeslots.api";
import Checkbox from "../../Checkbox/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendar,
	faCheck,
	faClock,
	faEnvelope,
	faInfoCircle,
	faLanguage,
	faMoneyBill1Wave,
	faNoteSticky,
	faPencil,
	faPhone,
	faPlus,
	faRefresh,
	faStar,
	faTimes,
	faTrash,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminBooking from "../../Booking/AdminBooking/AdminBooking";
import FlagEn from "../../../svg/FlagEn.svg";
import FlagHu from "../../../svg/FlagHu.svg";
import { AppointmentStatus } from "../../Booking/Booking";

const SHOW_FUTURE_APPOINTMENTS = "show_future_appointments";

type Service = typeof PriceInfo["Manicure"][0];

type AppointmentResponse = {
	id: string;
	from: string;
	name: string;
	email: string;
	tel: string;
	serviceId: number;
	isEn: boolean;
	note?: string;
	depositPaid?: boolean;
	status?: AppointmentStatus;
};

export type TransformedAppointment = {
	id: string;
	name: string;
	email: string;
	tel: string;
	service: Service;
	from: dayjs.Dayjs;
	isEn: boolean;
	note?: string;
	depositPaid?: boolean;
	status?: AppointmentStatus;
};

type Props = { default: boolean };

const AdminAppointments = ({}: Props) => {
	const token = useContext(TokenContext);
	const [weekdays, setWeekdays] = useState<dayjs.Dayjs[]>([]);
	const [timeslots, setTimeslots] = useState<FreeTimeslots>({});
	const [newBookingActive, setNewBookingActive] = useState(false);
	const [modifiedAppointment, setModifiedAppointment] =
		useState<TransformedAppointment | null>(null);
	const [timeslotFilters, setTimeslotFilters] = useState<string[]>([]);
	const [showFutureAppointments, setShowFutureAppointments] = useState(
		localStorage.getItem(SHOW_FUTURE_APPOINTMENTS) !== null
	);

	const [appointments, setAppointments] = useState<TransformedAppointment[]>(
		[]
	);

	const calendarTimeslots = useMemo(() => {
		return timeslotsToCalendarTimeslots(timeslots, weekdays, true);
	}, [timeslots, weekdays]);

	const fetchFreeTimeslots = async () => {
		const newTimeslots = await getFreeTimeslotsAfter(
			token,
			weekdays[0].hour(0).minute(0).second(0)
		);
		setTimeslots(newTimeslots);
	};

	const fetchTimeslots = async () => {
		try {
			await fetchFreeTimeslots();
		} catch (e) {
			console.error("ERR!", e);
		}
	};

	useEffect(() => {
		if (!weekdays?.length || !token?.length) return;
		fetchTimeslots();
	}, [weekdays, token]);

	const fetchAppointmentDetails = async () => {
		if (!token) return;

		const services = (() => {
			return Object.values(PriceInfo)
				.flat()
				.reduce((acc, curr) => {
					acc[curr.id] = curr as unknown as Service;
					return acc;
				}, {} as Record<number, Service>);
		})();

		try {
			const response = await fetchAppointments(token, weekdays[0].toDate());
			const json = (await response.json()) as AppointmentResponse[];
			setAppointments(
				json
					// .filter((a) => a.status !== "rejected")
					.map<TransformedAppointment>((a) => ({
						id: a.id,
						email: a.email,
						tel: a.tel,
						name: a.name,
						serviceId: a.serviceId,
						service: services[a.serviceId] ?? {
							name: "ISMERETLEN SZOLGÁLTATÁS!",
							price: 123,
							timeMax: 45,
						},
						from: dayjs(a.from).utc(),
						isEn: a.isEn,
						note: a.note,
						depositPaid: a.depositPaid,
						status: a.status,
					}))
					.sort((a, b) => (a.from.toDate() as any) - (b.from.toDate() as any))
			);
		} catch (err) {
			console.error("Error fetching appointments!", err);
		}
	};

	useEffect(() => {
		fetchAppointmentDetails();
	}, [token, weekdays]);

	const deleteClicked = async (id: string) => {
		if (confirm(`Biztos, hogy törölni akarod a foglalást?`)) {
			try {
				await deleteAppointment(token, id);
				setAppointments(appointments.filter((a) => a.id !== id));
			} catch {
				alert("Hiba a foglalás törlése közben!");
			}
		}
	};

	const approveClicked = async (id: string, approved: boolean) => {
		const message = approved
			? "Biztos, hogy meg akarod erősíteni a foglalást?"
			: "Biztos, hogy el akarod utasítani a foglalást?";
		if (confirm(message)) {
			try {
				await approveAppointment(token, id, approved);
				await fetchAppointmentDetails();
			} catch {
				alert("Hiba a foglalás elfogadása közben!");
			}
		}
	};

	const handleModifyClicked = async (id: string) => {
		const appointment = appointments.find((a) => a.id === id);
		if (!appointment) {
			console.error({
				err: "Appointment not found",
				id,
				appointments,
			});
			alert("A foglalás nem szerkeszthető ismeretlen hiba miatt!");
			return;
		}

		setModifiedAppointment(appointment);
		setNewBookingActive(true);
		scrollTo(0, 0);
	};

	const selectTimeslot = (timeslot: Timeslot) => {
		// TODO: make this feature work
		return;
		const id = appointments.find(
			(appointment) => !timeslot.from.diff(appointment.from)
		)?.id;

		if (id) {
			setTimeslotFilters([id!]);
		}
	};

	const filteredAppointments = useMemo(() => {
		const defaultFilteredAppointments = appointments.filter((a) => {
			if (showFutureAppointments) {
				return a.from.isAfter(weekdays[0]);
			} else {
				return (
					a.from.isAfter(weekdays[0]) &&
					a.from.isBefore(weekdays[6].add(1, "days"))
				);
			}
		});

		// TODO: make this work
		// if (!Object.values(timeslotFilters).length) {
		return defaultFilteredAppointments;
		// }

		// TODO: instead of removing entirely, fade out
		return defaultFilteredAppointments.filter(({ id }) =>
			timeslotFilters.includes(id)
		);
	}, [timeslotFilters, appointments, timeslots, showFutureAppointments]);

	const handleNewAppointmentClick = async () => {
		await refreshAppointments();
		flushSync(() => setNewBookingActive(false));
		flushSync(() => setNewBookingActive(true));
	};

	const refreshAppointments = async () => {
		return Promise.all([fetchTimeslots(), fetchAppointmentDetails()]);
	};

	const bookingTimeslots = useMemo(() => {
		if (!modifiedAppointment) return timeslots;

		const timeslotsWithModifiedAsFree = { ...timeslots };

		const year = modifiedAppointment.from.year();
		const month = modifiedAppointment.from.month() + 1;
		const day = modifiedAppointment.from.date();
		const hour = modifiedAppointment.from.hour();
		const minute = modifiedAppointment.from.minute();

		const timeslotsForDay = timeslotsWithModifiedAsFree[year]?.[month]?.[day];
		let foundTimeslotId = "";
		Object.entries(timeslotsForDay).forEach(([id, timeslotTime]) => {
			if (!id.startsWith("-")) return;

			// e.g. 9:0-10:0 (from-to)
			const [fromHour, fromMinute] = timeslotTime
				.split("-")[0]
				.split(":")
				.map(Number);
			if (fromHour === hour && fromMinute === minute) {
				foundTimeslotId = id;
			}
		});

		if (!foundTimeslotId?.length) {
			console.error({
				err: "Timeslot not found",
				modifiedAppointment,
				timeslotsForDay,
			});
			return timeslots;
		}

		const foundTimeslot =
			timeslotsWithModifiedAsFree[year][month][day][foundTimeslotId];
		delete timeslotsWithModifiedAsFree[year][month][day][foundTimeslotId];
		const newTimeslotId = 800000; // safe to assume this id is not used
		timeslotsWithModifiedAsFree[year][month][day][newTimeslotId] =
			foundTimeslot;

		// when modifying an appointment, the previous timeslot should be freed
		return timeslotsWithModifiedAsFree;
	}, [timeslots, modifiedAppointment]);

	return (
		<S.Container>
			<S.BookingContainer>
				<div>
					{newBookingActive ? (
						<>
							<AdminBooking
								timeslots={bookingTimeslots}
								handleNewAppointmentClick={handleNewAppointmentClick}
								onBookingSuccess={refreshAppointments}
								previousAppointment={modifiedAppointment!}
							/>
							<Button
								appearance="danger"
								onClick={() => {
									if (confirm("Biztos, hogy megszakítod a foglalást?")) {
										setModifiedAppointment(null);
										setNewBookingActive(false);
									}
								}}
							>
								Foglalás megszakítása
							</Button>
						</>
					) : (
						<Button
							appearance="primary"
							onClick={() => setNewBookingActive(true)}
							icon={faPlus}
						>
							Új foglalás
						</Button>
					)}
				</div>
				<S.CalendarContainer>
					<Calendar
						timeslots={calendarTimeslots}
						onWeekChange={(e) => setWeekdays(e)}
						onTimeslotSelect={selectTimeslot}
						fetching={false}
						error={false}
						readonly
						isAdmin
					/>
					<S.FilterButtons>
						{/* TODO make filters work */}
						{/* <Button
							disabled={!timeslotFilters.length}
							appearance="warning"
							onClick={() => setTimeslotFilters([])}
							icon={faFilter}
						>
							Filterek törlése
						</Button> */}
						<Checkbox
							checked={showFutureAppointments}
							onChange={(e) => {
								setShowFutureAppointments(e.currentTarget.checked);
								if (e.currentTarget.checked) {
									localStorage.setItem(SHOW_FUTURE_APPOINTMENTS, "");
								} else {
									localStorage.removeItem(SHOW_FUTURE_APPOINTMENTS);
								}
							}}
						>
							Minden jövőbeni foglalás megjelenítése
						</Checkbox>
						<S.RefreshButtonContainer>
							<Button
								appearance="primary"
								onClick={refreshAppointments}
								icon={faRefresh}
							>
								Foglalások frissítése
							</Button>
						</S.RefreshButtonContainer>
					</S.FilterButtons>
					<S.WaitingForApprove>
						Jóváhagyásra vár:{" "}
						{appointments.filter((a) => a.status === 0).length}
					</S.WaitingForApprove>
				</S.CalendarContainer>
			</S.BookingContainer>
			{filteredAppointments.map((appointment, index) => (
				<Fragment key={appointment.id}>
					{(!index ||
						!filteredAppointments[index - 1].from.isSame(
							appointment.from,
							"day"
						)) && (
						<S.AppointmentNewDateTitle>
							{`${getWeekDayName(
								appointment.from,
								false
							)} ${appointment.from.format("YYYY.MM.DD.")}`}
						</S.AppointmentNewDateTitle>
					)}
					<S.Appointment
						$isBeingModified={
							!!modifiedAppointment && modifiedAppointment.id === appointment.id
						}
					>
						{appointment.status === AppointmentStatus.Pending && (
							<S.AppointmentButtons $withMargin>
								<Button
									appearance="success"
									onClick={() => {
										approveClicked(appointment.id, true);
									}}
									icon={faCheck}
								>
									Elfogadás
								</Button>
								<Button
									appearance="danger"
									onClick={() => {
										approveClicked(appointment.id, false);
									}}
									icon={faTimes}
								>
									Elutasítás
								</Button>
							</S.AppointmentButtons>
						)}
						<div>
							<FontAwesomeIcon icon={faCalendar} fixedWidth />
							{appointment.from
								.toDate()
								.toLocaleDateString("hu", { dateStyle: "medium" })}
						</div>
						<div>
							<FontAwesomeIcon icon={faClock} fixedWidth />
							{formatFromTo(
								appointment.from,
								appointment.from.add(appointment.service.timeMax, "minutes")
							)}
						</div>
						<div>
							<FontAwesomeIcon icon={faInfoCircle} fixedWidth />
							{appointment.service.name} - {appointment.service.price} Ft
						</div>
						<div>
							<FontAwesomeIcon icon={faUser} fixedWidth />
							{appointment.name}
						</div>
						<div>
							<FontAwesomeIcon icon={faEnvelope} fixedWidth />
							<a href={`mailto:${appointment.email}`}>{appointment.email}</a>
						</div>
						<div>
							<FontAwesomeIcon icon={faPhone} fixedWidth />
							<a href={`tel:${appointment.tel}`}>{appointment.tel}</a>
						</div>
						<div>
							<FontAwesomeIcon icon={faLanguage} fixedWidth />
							<S.FlagContainer>
								{appointment.isEn ? <FlagEn /> : <FlagHu />}
							</S.FlagContainer>
						</div>
						<div>
							<FontAwesomeIcon icon={faMoneyBill1Wave} fixedWidth />
							<S.DepositContainer depositPaid={appointment.depositPaid}>
								{appointment.depositPaid ? (
									<FontAwesomeIcon icon={faCheck} />
								) : (
									<FontAwesomeIcon icon={faTimes} />
								)}
							</S.DepositContainer>
						</div>
						<div>
							<FontAwesomeIcon icon={faStar} fixedWidth />
							{AppointmentStatus[appointment.status!]}
						</div>
						<div>
							<FontAwesomeIcon icon={faNoteSticky} fixedWidth />
							{appointment.note}
						</div>
						<S.AppointmentButtons>
							<Button
								appearance="primary"
								size="small"
								onClick={() => handleModifyClicked(appointment.id)}
								icon={faPencil}
							>
								Módosítás
							</Button>
							<Button
								appearance="danger"
								size="small"
								onClick={() => deleteClicked(appointment.id)}
								icon={faTrash}
							>
								Törlés
							</Button>
						</S.AppointmentButtons>
					</S.Appointment>
				</Fragment>
			))}
		</S.Container>
	);
};

export default AdminAppointments;
